import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MapSrc from "../images/local.png"
import ComputerSrc from "../images/computer.jpg"
import BeerSrc from "../images/beer-glasses.jpg"

const IndexPage = () => (
  <Layout pageName="home">
    <SEO title="Home" />
    <div id="featured">
      <div className="container"></div>
      <div className="container callout">
        <h2 className="header">Brewing Amazing Beer</h2>
        <div className="row">With the Alexandria Society of Homebrewers</div>
        <div className="row">we're bringing new and interesting beer</div>
        <div className="row">to Alexandria and beyond.</div>

        <div className="cta">
          <Link to="/about" className="more">
            read more
          </Link>
        </div>
      </div>
    </div>

    <div className="section">
      <div className="gallery">
        <ul>
          <li>
            <Link to="/beers">
              <img src={BeerSrc} alt="" />
              <span className="bottom-title">Rotating Beers</span>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <img src={MapSrc} alt="" />
              <span className="bottom-title">Locally Focused</span>
            </Link>
          </li>
          <li>
            <a href="/">
              <img src={ComputerSrc} alt="" />
              <span className="bottom-title">Technology Enabled</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default IndexPage
